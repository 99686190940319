/**
 * plugins/index.js
 *
 * Automatically included in `./src/main.js`
 */

// Plugins
import vuetify from './vuetify'
import { firebaseApp } from '@/plugins/firebase'
import {useFirebaseAuth, VueFire, VueFireAuth} from 'vuefire'
import router from "@/plugins/router";
import {pinia} from "@/plugins/pinia";

export function registerPlugins (app) {
  app.use(vuetify)
  app.use(router)
  app.use(pinia)
  app.use(VueFire, {
      firebaseApp,
      // add modules like VueFireAuth, ...
      modules: [
        VueFireAuth()
      ],
    })
}

