
import { defineStore } from "pinia";
import {getCurrentUser} from "vuefire";
import { getIdTokenResult } from "firebase/auth";

import {getDocs, query, where, limit, count, getAggregateFromServer} from "firebase/firestore";
import {cards, subscriptions} from "@/plugins/firebase";
import {httpsCallable} from "firebase/functions";
import {useFirebaseFunctions} from "@/helpers/useFirebaseFunctions";
import {shallowRef} from "vue";
export const useUserStore = defineStore({
  id: "user",
  state: () => ({
    cards: null,
    card: null,
    user: null,
    subscription: null,
    premium: false,
    has2FA: false,
    admin: false,
    premiumParent: null,
    cardCount: null
  }),
  getters: {
    cardLimit() {
      if(!this.subscription) return 1;

      return parseInt(this.subscription?.plan?.metadata?.limit||1);
    },
    canUpgrade() {
      if(this.card?.locked) {
        return false;
      }

      if(this.premium) {
        return false;
      }

      return true;
    }
  },
  actions: {
    async loadUser() {
        this.user = await getCurrentUser();

        if(this.user) {
          const vm = this;
          getIdTokenResult(this.user).then((idTokenResult) => {
            vm.admin = !!idTokenResult.claims.admin;
            vm.has2FA = !!idTokenResult.claims.firebase.sign_in_second_factor;
          }).catch((error) => {
            console.error("Error fetching ID token result:", error);
          });
        }

    },
    async loadInheritedRights(force = false) {
      if(this.premiumParent !== null && !force) return;
      const functions = useFirebaseFunctions();
      const removeAccess = httpsCallable(functions, 'isPremiumCard');

      if(!this.card.authorized_uids || !this.card.authorized_uids.length) {
        this.premiumParent = false;
        return;
      }

      const response = await removeAccess({card_ids: this.card.authorized_uids})
      this.premiumParent = response.data;

    },
    async loadSubscription(force = false) {
      if(!this.user) return;
      if (this.subscription !== null && !force) return;

      try {
        const functions = useFirebaseFunctions();

        const getSubscription = httpsCallable(functions, 'getSubscription')
        const result = await getSubscription();

        if (result.data) {
          this.subscription = result.data;
          this.premium = true;
        } else {
          this.subscription = false;
          this.premium = false;
        }
      } catch (e) {
        console.error(e);
      }
    },
    async loadCardCounts() {
      if (!this.user || this.cardCount !== null) return

      const userOwnedCardsQuery = query(
        cards,
        where("owner_uid", "==", this.user.uid)
      );

      const authorizedCardsQuery = query(
        cards,
        where("authorized_uids", "array-contains", this.user.uid)
      );

      // Voer beide count queries tegelijk uit
      const [ownedCountSnap, authorizedCountSnap] = await Promise.all([
        getAggregateFromServer(userOwnedCardsQuery, { count: count() }),
        getAggregateFromServer(authorizedCardsQuery, { count: count() }),
      ]);


      this.cardCount = (ownedCountSnap.data().count || 0) + (authorizedCountSnap.data().count || 0)
    },
    async loadCards(force = false, search = null) {
      if (!this.user) return;

      if (this.cards && !force) return;

      // Base queries
      let userOwnedCardsQuery = query(
        cards,
        where("owner_uid", "==", this.user.uid)
      );

      let authorizedCardsQuery = query(
        cards,
        where("authorized_uids", "array-contains", this.user.uid),
        limit(Math.max(this.cardLimit-1, 1)) // Limit authorized cards, own minus authorized
      );

      // Fetch both sets in parallel
      const [ownedCardsSnapshot, authorizedCardsSnapshot] = await Promise.all([
        getDocs(userOwnedCardsQuery),
        getDocs(authorizedCardsQuery),
      ]);

      // Convert snapshots to objects
      let ownedCards = ownedCardsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      let authorizedCards = authorizedCardsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

      // Apply search filter if needed
      if (search) {
        const lowerSearch = search.toLowerCase();
        authorizedCards = authorizedCards.filter(card => card.full_name.toLowerCase().includes(lowerSearch));
        ownedCards = ownedCards.filter(card => card.full_name.toLowerCase().includes(lowerSearch));
      }

      // Merge results (avoid duplicates)
      const mergedCards = new Map();
      ownedCards.forEach(card => mergedCards.set(card.id, card));
      authorizedCards.forEach(card => mergedCards.set(card.id, card));

      // Set first owned card
      if (ownedCards.length > 0) {
        this.card = { id: ownedCards[0].id, ...ownedCards[0] };
      }

      // Store merged results
      this.cards = shallowRef([...mergedCards.values()]);

      // Load inherited rights
      this.loadInheritedRights();
    }
  },
});
